<template>
  <span :class="$style.root">
    {{ name }}
  </span>
</template>

<script>
import { WORKS } from '@/store/actsvr/enums';
import { get, find } from 'lodash-es';

export default {
  name: 'WorkChip',
  props: {
    value: { type: String },
  },
  computed: {
    name() {
      return get(find(WORKS, { value: this.value }), 'text');
    },
  }
}
</script>

<style module lang="scss">
.root {}
</style>
