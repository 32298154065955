<template>
  <div :class="$style.root">
    <VDataTable
        :headers="headers"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        :item-class="rowClassControl"
        @click:row="({ id }) => onDetail(id)"
        @update:page="page => onPagination({ page })"
        @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:body.prepend>
        <FilterRow :items="headers" @input="onPagination" />
      </template>
      <template v-slot:item.status="{ item: { status } }">
        <StatusChip :value="status" />
      </template>
      <template v-slot:item.work="{ item: { work }}">
        <WorkChip :value="work" />
      </template>
      <template v-slot:item.place="{ item: { place }}">
        <PlaceChip :value="place" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import FilterRow from '@/components/actsvr/FilterRow/FilterRow';
import StatusChip from './components/StatusChip';
import PlaceChip from './components/PlaceChip';
import WorkChip from './components/WorkChip';
export default {
  name: 'AcceptanceList',
  components: {
    StatusChip,
    PlaceChip,
    FilterRow,
    WorkChip,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
    }
  },
  methods: {
    onDetail(id) {
      this.$router.push({ name: 'actsvr/AcceptanceDetailView', params: { acceptance: id }})
    },
    onPagination(params) {
      const { query } = this.$route;
      this.$router.push({ query: { ...query, ...params } });
    },
    rowClassControl() {
      return '';
    }
  }
}
</script>

<style module lang="scss">
.root {
  th {
    width: 1px !important;
    &:nth-child(n + 5) {
      width: 200px !important;
    }
  }
  td {
    cursor: pointer;
  }
}
</style>
